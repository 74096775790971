
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Radio",
  props: {
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    value: { default: '' },
    checked: { type: Boolean, default: false }
  },
  setup(props, { emit }) {

    const set = () => {
      emit("change", props.label);
    };

    return { set };
  },
});

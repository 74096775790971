
import {computed, ComputedRef, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Select",
  props: {
    choices: { type: Object, default: () => [] },
    choiceSelected: { type: String },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: true },
    helperMsg: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    //DATA
    const isOpen = ref(false);

    const label: ComputedRef<string> = computed(() => {
      if (props.choiceSelected) return props.choices.find((item: any) => item.id === props.choiceSelected).name;
      else return false;
    });

    const selectChoice = (choice: any) => {
      isOpen.value = false;
      emit("change", choice.id);
    };

    return { isOpen, label, selectChoice }
  },
});
